/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"} description={"Fotografie ze svatby"}>
        <Column className="pb--10 pt--30" name={"fotogalerie_uvod"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mb--40 mt--10" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1 fs--36 w--400" content={"Fotogalerie"}>
              </Title>

              <Text className="text-box ff--1 fs--18 w--300" style={{"maxWidth":786}} content={"Děkujeme všem, kteří s námi strávili náš den, přišli nás podpořit na radnici nebo <br>do Mlýnské Strouhy či nás podpořili jinak.<br>Velmi si toho vážíme!<br><br>A také děkujeme za úžasné fotky fotografovi, panu  Filipovi, <a href=\"https://www.filipfotograf.cz/\">www.filipFOTOGRAF.cz</a>&nbsp; ;-)<br>"}>
              </Text>

              <Image className="mt--40" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ca31e8f0aadc4efcb831d652324e5770_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7361/ca31e8f0aadc4efcb831d652324e5770_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/ca31e8f0aadc4efcb831d652324e5770_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/ca31e8f0aadc4efcb831d652324e5770_s=860x_.jpg 860w"} content={null} position={{"x":"-78.51369863013699%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--0" name={"afzv7xhjka"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ef918dbf12ea4768be69de48c0c96dbb_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/ef918dbf12ea4768be69de48c0c96dbb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/ef918dbf12ea4768be69de48c0c96dbb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/ef918dbf12ea4768be69de48c0c96dbb_s=860x_.jpg 860w"} content={null}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/565d6e2e6679487eaaa88010aab53384_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/565d6e2e6679487eaaa88010aab53384_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/565d6e2e6679487eaaa88010aab53384_s=660x_.jpg 660w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/b291a56802c749f9bd23ffd6e59cb318_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/b291a56802c749f9bd23ffd6e59cb318_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/b291a56802c749f9bd23ffd6e59cb318_s=660x_.jpg 660w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/6529072bffde415fbeb00c53f68f7e5e_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/6529072bffde415fbeb00c53f68f7e5e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/6529072bffde415fbeb00c53f68f7e5e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/6529072bffde415fbeb00c53f68f7e5e_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--10" name={"k5fv5942amt"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/81ad00c9e9f44149b193fe53562dc5ad_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/81ad00c9e9f44149b193fe53562dc5ad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/81ad00c9e9f44149b193fe53562dc5ad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/81ad00c9e9f44149b193fe53562dc5ad_s=860x_.jpg 860w"} content={null} position={{"x":"-0.5347593582887701%","y":"0%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/5c43c5c511ca42f398d76808acb8d000_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/5c43c5c511ca42f398d76808acb8d000_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/5c43c5c511ca42f398d76808acb8d000_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/5c43c5c511ca42f398d76808acb8d000_s=860x_.jpg 860w"} content={null} position={{"x":"-24.650623885917952%","y":"-67.45422116527944%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/bfbd71304d59408ba931a5fc431f73b9_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/bfbd71304d59408ba931a5fc431f73b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/bfbd71304d59408ba931a5fc431f73b9_s=660x_.jpg 660w"} content={null} position={{"x":"0%","y":"-8.167657550535077%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/7121d47d9d7e4b05a5a36ad98c87d17c_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/7121d47d9d7e4b05a5a36ad98c87d17c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/7121d47d9d7e4b05a5a36ad98c87d17c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/7121d47d9d7e4b05a5a36ad98c87d17c_s=860x_.jpg 860w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--20" name={"7b78s66qmcv"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/4d4d6e20c05a4c1788c532861482289b_e=0x127x998x561_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/4d4d6e20c05a4c1788c532861482289b_e=0x127x998x561_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/4d4d6e20c05a4c1788c532861482289b_e=0x127x998x561_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/4d4d6e20c05a4c1788c532861482289b_e=0x127x998x561_s=860x_.jpg 860w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/b0e2a072c8c94f2ca0fe3ce91d2cf360_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/b0e2a072c8c94f2ca0fe3ce91d2cf360_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/b0e2a072c8c94f2ca0fe3ce91d2cf360_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/b0e2a072c8c94f2ca0fe3ce91d2cf360_s=860x_.jpg 860w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/d21c10286e5149539f93f017bcd8763a_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/d21c10286e5149539f93f017bcd8763a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/d21c10286e5149539f93f017bcd8763a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/d21c10286e5149539f93f017bcd8763a_s=860x_.jpg 860w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/a7a184a49edd4eeeaf1d562c0d0a6101_e=0x438x998x562_s=660x_.jpg"} svg={false} sizes={"100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7361/a7a184a49edd4eeeaf1d562c0d0a6101_e=0x438x998x562_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/a7a184a49edd4eeeaf1d562c0d0a6101_e=0x438x998x562_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/a7a184a49edd4eeeaf1d562c0d0a6101_e=0x438x998x562_s=860x_.jpg 860w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"co1ymp8wftm"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/917f46798b6f4b399f09d05f90ae9c06_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/917f46798b6f4b399f09d05f90ae9c06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/917f46798b6f4b399f09d05f90ae9c06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/917f46798b6f4b399f09d05f90ae9c06_s=860x_.jpg 860w"} content={null} position={{"x":"-58.085561497326196%","y":"-74.32620320855615%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/e40aa418aff249708d956472e6b55c0c_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/e40aa418aff249708d956472e6b55c0c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/e40aa418aff249708d956472e6b55c0c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/e40aa418aff249708d956472e6b55c0c_s=860x_.jpg 860w"} content={null} position={{"x":"-100%","y":"-73.83234244946492%"}}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/a22e9a39188841c5ad1e0eb4f2f27b50_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/a22e9a39188841c5ad1e0eb4f2f27b50_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/a22e9a39188841c5ad1e0eb4f2f27b50_s=660x_.jpg 660w"} content={null}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_e=2x0x998x561_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_e=2x0x998x561_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_e=2x0x998x561_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_e=2x0x998x561_s=860x_.jpg 860w"} content={null}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--40 pt--20" name={"jgsjlivulc"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/f37d49c7aacf45ff80d143f48ea17cc8_e=0x486x663x373_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/f37d49c7aacf45ff80d143f48ea17cc8_e=0x486x663x373_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/f37d49c7aacf45ff80d143f48ea17cc8_e=0x486x663x373_s=660x_.jpg 660w"} content={null} position={{"x":"0%","y":"-10.408921933085503%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/8119cdfb994349d69737213e107b5c08_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/8119cdfb994349d69737213e107b5c08_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/8119cdfb994349d69737213e107b5c08_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/8119cdfb994349d69737213e107b5c08_s=860x_.jpg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/9ecc1809085845a389df428d891c2efa_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/9ecc1809085845a389df428d891c2efa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/9ecc1809085845a389df428d891c2efa_s=660x_.jpg 660w"} content={null} position={{"x":"-100%","y":"-45.88475836431226%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/80f2e6b7425442378e461a6cd8c50401_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/80f2e6b7425442378e461a6cd8c50401_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/80f2e6b7425442378e461a6cd8c50401_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/80f2e6b7425442378e461a6cd8c50401_s=860x_.jpg 860w"} content={null} position={{"x":"-100%","y":"-1.8587360594795541%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/00611528e2d7482da4416a250cba7cde_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/00611528e2d7482da4416a250cba7cde_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/00611528e2d7482da4416a250cba7cde_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/00611528e2d7482da4416a250cba7cde_s=860x_.jpg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/dfd3645153384b3cb77a8b1635b5a900_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7361/dfd3645153384b3cb77a8b1635b5a900_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/dfd3645153384b3cb77a8b1635b5a900_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/dfd3645153384b3cb77a8b1635b5a900_s=860x_.jpg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--20" name={"kompletni_fotogalerie"} style={{"backgroundColor":"rgba(197, 210, 235, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1 fs--36 w--400" content={"Kompletní fotogalerii<br>"}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--18" content={"ze svatby Plašanů<br>"}>
              </Subtitle>

              <Text className="text-box ff--1 fs--18 w--300" content={"naleznete v následujícím odkazu.&nbsp; ;-)<br>"}>
              </Text>

              <Button className="btn-box" url={"https://drive.google.com/file/d/1PkpU3i8c4yYPOirbFx2KnAWyjaeFVSt4/view?usp=sharing"} href={"https://drive.google.com/file/d/1PkpU3i8c4yYPOirbFx2KnAWyjaeFVSt4/view?usp=sharing"} content={"kompletní fotogalerie"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}